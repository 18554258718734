import { ComponentPropsWithoutRef } from 'react';
import classNames from 'classnames';
import { ChevronLeft, ChevronRight } from 'lucide-react';
interface Props<T extends React.ElementType> {
  direction: 'left' | 'right';
  as?: T;
}
const ChevronButton = <T extends React.ElementType,>({
  as,
  direction,
  className,
  ...props
}: Props<T> & ComponentPropsWithoutRef<T>) => {
  const Component = as || 'button';
  const Chevron = direction === 'left' ? ChevronLeft : ChevronRight;
  return <Component className={classNames('flex h-[32px] w-[32px] items-center justify-center rounded-full bg-[--shade-2] text-[24px] text-grey-850 transition-colors duration-200 hover:bg-[--shade-3] md:h-[48px] md:w-[48px]', className)} {...props} data-sentry-element="Component" data-sentry-component="ChevronButton" data-sentry-source-file="chevron-button.tsx">
      <Chevron className={classNames('h-[24px] w-[24px] stroke-[1.5]  md:h-[32px] md:w-[32px]', {
      'ml-[-4px]': direction === 'left',
      'mr-[-4px]': direction === 'right'
    })} data-sentry-element="Chevron" data-sentry-source-file="chevron-button.tsx" />
    </Component>;
};
export default ChevronButton;