/* eslint-disable unicorn/prefer-query-selector */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
'use client';

import type { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { BUY_BUTTON_ID } from '@/constants/common';
import { ContentfulContentBlock, ContentfulEvent, ContentfulIcon, ContentfulImage } from '@/types/contentful';
import { useBuyTicket } from '../../hooks/useBuyTicket';
import type { ButtonProps } from '../../types/button';
import type { ExtendedContentfulLink } from '../../types/link';
import { AppStoreBanner } from '../app-store-banner';
import Button from '../Button';
import Icon from '../Icon';
import { ContentBlockIcons } from './ContentBlockIcons';
import ContentBlockImage from './ContentBlockImage';
export interface ContentBlockProps {
  readonly event?: ContentfulEvent;
  readonly title: string;
  readonly subtitle?: string;
  readonly description?: ReactNode;
  readonly image?: ContentfulImage;
  readonly buttonProps?: ButtonProps;
  readonly imagePosition?: ContentfulContentBlock['imagePosition'];
  readonly links?: ExtendedContentfulLink[];
  readonly showTransparentBackground?: boolean;
  readonly icons?: ContentfulIcon[];
  readonly showTitle?: boolean;
  readonly useH1TagForTitle?: boolean;
  readonly showAppStoreBanners?: boolean;
  readonly socialMediaIconsTitle?: string;
  readonly isFirstBlock?: boolean;
  readonly canShowFullImage?: boolean;
}
const ContentBlockComponent: FC<ContentBlockProps> = ({
  event,
  title,
  subtitle,
  description,
  image,
  buttonProps,
  imagePosition,
  links,
  showTransparentBackground,
  showAppStoreBanners,
  icons,
  showTitle = true,
  useH1TagForTitle = false,
  socialMediaIconsTitle,
  isFirstBlock,
  canShowFullImage = false
}) => {
  const imageUrl = image?.asset?.url;
  const HeadingType = useH1TagForTitle ? 'h1' : 'h3';
  const {
    cartStatus
  } = useBuyTicket(event!);

  // Workaround to buy the selected variant
  // it will forward the click to hero's buy button
  const handleBuyTicket = () => {
    const buyButton = document.getElementById(BUY_BUTTON_ID);
    buyButton?.click();
  };
  return <section className={classNames('content-block', {
    'content-block--show-transparent-background': isFirstBlock || showTransparentBackground,
    'first-block': isFirstBlock
  })} data-sentry-component="ContentBlockComponent" data-sentry-source-file="ContentBlockComponent.tsx">
      <div className="container container--vertical-padding">
        <div style={{
        height: '100%',
        flex: 1,
        flexGrow: 1,
        flexBasis: '100%'
      }} className={classNames('flex-column-reverse flex-lg-row row my-md-0', {
        'flex-lg-row-reverse': imagePosition === 'left',
        'flex-lg-row': imagePosition !== 'center',
        'flex-lg-column': imagePosition === 'center',
        'mx-auto': imagePosition === 'center',
        'justify-content-center': imagePosition === 'center',
        'align-items-center': imagePosition === 'center',
        'text-center': imagePosition === 'center'
      })}>
          <div className={classNames('d-flex container--padding-vertical content-block-container flex-column my-auto px-1', {
          'col-12 col-lg-6': imageUrl,
          'col-12 col-lg-8 mx-auto': !imageUrl,
          'justify-content-center': imagePosition === 'center',
          'align-items-between': imagePosition === 'center',
          'mb-5': imagePosition === 'center',
          'pe-lg-5': imageUrl && imagePosition === 'right',
          'ps-lg-5': imageUrl && imagePosition === 'left'
        })}>
            {subtitle && <div className={classNames('content-block__subtitle mb-1', {
            'content-block__subtitle--offset': imagePosition !== 'center'
          })}>
                <p>{subtitle}</p>
              </div>}
            {showTitle && <HeadingType className="title">{title}</HeadingType>}
            {description && <div className="description">{description}</div>}
            {icons && icons.length > 0 && <div className="content-block__icons mb-3">
                {icons.map(({
              type: iconName
            }) => <Icon key={`icon-${iconName}`} icon={iconName} />)}
              </div>}
            {buttonProps?.children && <Button className="content-block__call-to-action mb-3" onClick={buttonProps.isBuyButton ? handleBuyTicket : buttonProps.onClick} loading={buttonProps.isBuyButton && cartStatus === 'CREATE_CART_PENDING' || buttonProps.isBuyButton && cartStatus === 'ADD_CART_LINES_PENDING'} type={buttonProps.type} ariaLabel={buttonProps.ariaLabel} href={buttonProps.isBuyButton ? undefined : buttonProps.href}>
                {buttonProps.children}
              </Button>}
            {links && links.length > 0 && <div className={classNames('content-block__link-area d-flex flex-column mb-2', {
            'justify-content-center': imagePosition === 'center',
            'align-items-center': imagePosition === 'center',
            'text-center': imagePosition === 'center'
          })}>
                <ContentBlockIcons links={links} alignImageCenter={imagePosition === 'center'} socialMediaIconsTitle={socialMediaIconsTitle} />
              </div>}
            {showAppStoreBanners && <AppStoreBanner centered={imagePosition === 'center'} />}
          </div>
          <ContentBlockImage image={image} position={imagePosition} canShowFullImage={canShowFullImage} data-sentry-element="ContentBlockImage" data-sentry-source-file="ContentBlockComponent.tsx" />
        </div>
      </div>
    </section>;
};
export default ContentBlockComponent;